import { FC, useState, useEffect } from "react";
import { Media, MediaType } from "./media-worker";

const mediaClasses = "max-h-full max-w-full border shadow-lg object-contain";

const OmniMediaImageViewer: FC<{ src: string }> = ({ src }) => {
  return <img src={src} alt="" className={mediaClasses} />;
};

const OmniMediaVideoViewer: FC<{ src: string }> = ({ src }) => {
  return <video src={src} autoPlay muted className={mediaClasses} />;
};

const OmniMediaViewer: FC<Media> = ({ src, type }) => {
  let child;
  switch (type) {
    case MediaType.Image:
      child = <OmniMediaImageViewer src={src} />;
      break;
    case MediaType.Video:
      child = <OmniMediaVideoViewer src={src} />;
      break;
  }

  return (
    <section className="absolute inset-0 flex overflow-hidden">
      <div className="relative flex-1 flex">
        <div
          className="absolute inset-0 blur scale-125 z-10"
          style={{
            backgroundImage: `url(${src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />

        <div className="flex-1 flex justify-center items-center z-20 rounded-lg p-2">
          {child}
        </div>
      </div>
    </section>
  );
};

const OmniMedia: FC = () => {
  const [media, setMedia] = useState<Media>();

  useEffect(() => {
    const worker = new Worker(new URL("./media-worker.ts", import.meta.url));
    worker.onmessage = ({ data: media }: MessageEvent<Media>) => {
      setMedia(media);
    };

    return () => {
      worker.terminate();
    };
  }, []);

  if (media) {
    return <OmniMediaViewer {...media} />;
  }

  return null;
};

export default OmniMedia;
