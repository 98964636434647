/* eslint-disable no-restricted-globals */

export enum MediaType {
  Unknown,
  Image,
  Video,
}

export type Media = {
  src: string;
  type: MediaType;
};

const getMediaType = (type: string) => {
  if (type.startsWith("image")) {
    return MediaType.Image;
  } else if (type.startsWith("video")) {
    return MediaType.Video;
  } else {
    return MediaType.Unknown;
  }
};

const mediaFetcher = async () => {
  try {
    const response = await fetch("https://picsum.photos/1024/1024");
    const data = await response.blob();

    const mediaType = getMediaType(data.type);
    const mediaURL = URL.createObjectURL(data);

    postMessage({
      src: mediaURL,
      type: mediaType,
    });
  } catch (error) {
    console.error(error);
  }
};

const interval = setInterval(mediaFetcher, 5000);
addEventListener("close", () => {
  clearInterval(interval);
});

mediaFetcher();

/* eslint-enable no-restricted-globals */

export {};
